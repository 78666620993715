<template>
  <Loading v-if="isLoading" />
  <div v-else class="add-new-page">
    <b-card>
      <h2 class="m-0 page-title">
        {{
          isEdit
            ? $t("static_pages.edit_page")
            : $t("static_pages.add_new_page")
        }}
      </h2>
      <hr />
      <div class="message-info">
        <h3 class="sub-title mb-3">
          {{ $t("static_pages.page_information") }}
        </h3>
        <validation-observer ref="replyValidation">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('static_pages.page_name')"
                  label-for="Name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <b-form-input
                      type="text"
                      v-model="form_data.title"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                      class="custom-input"
                    />
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group :label="$t('static_pages.content')">
                  <validation-provider
                    #default="{ errors }"
                    name="content"
                    rules="required"
                  >
                    <VueEditor
                      v-model="form_data.content"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  :label="$t('static_pages.appearance_to')"
                  class="send-through"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Appearance to"
                    rules="required"
                  >
                    <div class="send-through-checkbox">
                      <b-form-checkbox
                        id="client-web"
                        v-model="form_data.is_web"
                        :value="1"
                        :unchecked-value="0"
                      >
                        {{ $t("static_pages.client_web") }}
                      </b-form-checkbox>

                      <b-form-checkbox
                        id="client-app"
                        v-model="form_data.is_mob"
                        :value="1"
                        :unchecked-value="0"
                      >
                        {{ $t("static_pages.client_app") }}
                      </b-form-checkbox>
                      <b-form-checkbox
                        id="delivery"
                        v-model="form_data.is_delivery"
                        :value="1"
                        :unchecked-value="0"
                      >
                        {{ $t("static_pages.delivery") }}
                      </b-form-checkbox>
                      <b-form-checkbox
                        id="vendor"
                        v-model="form_data.is_vendor"
                        :value="1"
                        :unchecked-value="0"
                      >
                        {{ $t("static_pages.vendor") }}
                      </b-form-checkbox>
                    </div>
                    <small class="text-danger">{{
                      errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  :label="$t('static_pages.appearance_locations')"
                  class="send-through"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Appearance locations"
                    rules="required"
                  >
                    <div class="send-through-checkbox">
                      <!-- <b-form-radio-group
                        id="radio-group-2"
                        v-model="form_data.page_place"
                        name="radio-place"
                      >
                        <b-form-radio value="header">
                          {{ $t("static_pages.nav_bar") }}</b-form-radio
                        >
                        <b-form-radio value="footer">{{
                          $t("static_pages.footer")
                        }}</b-form-radio>
                      </b-form-radio-group> -->
                      <b-form-checkbox
                        id="header"
                        v-model="form_data.is_header"
                        :value="1"
                        :unchecked-value="0"
                      >
                        {{ $t("static_pages.nav_bar") }}
                      </b-form-checkbox>

                      <b-form-checkbox
                        id="footer"
                        v-model="form_data.is_footer"
                        :value="1"
                        :unchecked-value="0"
                      >
                        {{ $t("static_pages.footer") }}
                      </b-form-checkbox>
                    </div>
                    <small class="text-danger">{{
                      errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-checkbox
              switch
              v-model="form_data.is_active"
              :value="1"
              :unchecked-value="0"
            >
              {{ $t("static_pages.show_this_page") }}
            </b-form-checkbox>

            <b-button
              type="submit"
              variant="primary"
              block
              @click="submitForm"
              class="primary-btn mt-3"
              :disabled="isPending"
            >
              <span>{{
                isEdit
                  ? $t("static_pages.save_changes")
                  : $t("static_pages.add_page")
              }}</span>
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormSelect,
  BFormTextarea,
  BForm,
  BFormGroup,
  BButton,
  BFormCheckbox,
  BFormInput,
  BFormRadioGroup,
  BFormRadio,
} from "bootstrap-vue";
import Loading from "@/components/shared/loading/loading.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { email, required } from "@core/utils/validations/validations";
import { VueEditor } from "vue2-editor";

export default {
  name: "AddPage",
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    Loading,
    BFormSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormInput,
    VueEditor,
    BFormRadio,
    BFormRadioGroup,
  },
  data() {
    return {
      isEdit: null,
      isPending: null,
      isLoading: true,
      overlayLoading: false,
      current_id: null,
      form_data: {
        title: null,
        content: null,
        is_footer: 0,
        is_header: 0,
        is_active: 0,
        is_web: 0,
        is_mob: 0,
        is_delivery: 0,
        is_vendor: 0,
      },

      valueArray: [
        "is_active",
        "is_web",
        "is_mob",
        "is_delivery",
        "is_vendor",
        "is_footer",
        "is_header",
      ],
    };
  },
  created() {
    if (this.$route.params.id) {
      this.current_id = this.$route.params.id;
      this.getPageData();
      this.isEdit = true;
    }
    this.isLoading = false;
  },
  methods: {
    async getPageData() {
      try {
        const response = (
          await this.$http.get(`admin/pages/${this.current_id}`)
        ).data.data;
        this.form_data = response;
        this.current_id = response.id;
        this.valueArray.map((el) => {
          this.form_data[el] = Number(response[el]);
        });
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    submitForm() {
      this.$refs.replyValidation.validate().then((success) => {
        if (success) {
          this.isPending = true;
          const formData = new FormData();
          this.valueArray.map((el) => {
            formData.append(el, Number(this.form_data[el]));
          });
          formData.append("title", this.form_data.title);
          formData.append("content", this.form_data.content);
          formData.append("lang", localStorage.getItem("lang"));

          if (this.isEdit) {
            this.$http
              .post(`admin/pages/${this.current_id}?_method=PUT`, formData)
              .then((res) => {
                if (res.status === 200 || res.status === 201) {
                  this.$helpers.makeToast(
                    "success",
                    res.data.message,
                    res.data.message
                  );
                  setTimeout(() => {
                    this.$router.push({ name: "staticPage" });
                  }, 1000);
                  this.isPending = false;
                }
              })
              .catch((err) => {
                this.$helpers.handleError(err);
                this.isPending = false;
              });
          } else {
            this.$http
              .post("admin/pages", formData)
              .then((res) => {
                if (res.status === 200 || res.status === 201) {
                  this.$helpers.makeToast(
                    "success",
                    res.data.message,
                    res.data.message
                  );
                  setTimeout(() => {
                    this.$router.push({ name: "staticPage" });
                  }, 1000);
                  this.isPending = false;
                }
              })
              .catch((err) => {
                this.$helpers.handleError(err);
                this.isPending = false;
              });
          }
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "./index";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
