var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('Loading'):_c('div',{staticClass:"add-new-page"},[_c('b-card',[_c('h2',{staticClass:"m-0 page-title"},[_vm._v(" "+_vm._s(_vm.isEdit ? _vm.$t("static_pages.edit_page") : _vm.$t("static_pages.add_new_page"))+" ")]),_c('hr'),_c('div',{staticClass:"message-info"},[_c('h3',{staticClass:"sub-title mb-3"},[_vm._v(" "+_vm._s(_vm.$t("static_pages.page_information"))+" ")]),_c('validation-observer',{ref:"replyValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('static_pages.page_name'),"label-for":"Name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"custom-input",attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.form_data.title),callback:function ($$v) {_vm.$set(_vm.form_data, "title", $$v)},expression:"form_data.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('static_pages.content')}},[_c('validation-provider',{attrs:{"name":"content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VueEditor',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.content),callback:function ($$v) {_vm.$set(_vm.form_data, "content", $$v)},expression:"form_data.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"send-through",attrs:{"label":_vm.$t('static_pages.appearance_to')}},[_c('validation-provider',{attrs:{"name":"Appearance to","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"send-through-checkbox"},[_c('b-form-checkbox',{attrs:{"id":"client-web","value":1,"unchecked-value":0},model:{value:(_vm.form_data.is_web),callback:function ($$v) {_vm.$set(_vm.form_data, "is_web", $$v)},expression:"form_data.is_web"}},[_vm._v(" "+_vm._s(_vm.$t("static_pages.client_web"))+" ")]),_c('b-form-checkbox',{attrs:{"id":"client-app","value":1,"unchecked-value":0},model:{value:(_vm.form_data.is_mob),callback:function ($$v) {_vm.$set(_vm.form_data, "is_mob", $$v)},expression:"form_data.is_mob"}},[_vm._v(" "+_vm._s(_vm.$t("static_pages.client_app"))+" ")]),_c('b-form-checkbox',{attrs:{"id":"delivery","value":1,"unchecked-value":0},model:{value:(_vm.form_data.is_delivery),callback:function ($$v) {_vm.$set(_vm.form_data, "is_delivery", $$v)},expression:"form_data.is_delivery"}},[_vm._v(" "+_vm._s(_vm.$t("static_pages.delivery"))+" ")]),_c('b-form-checkbox',{attrs:{"id":"vendor","value":1,"unchecked-value":0},model:{value:(_vm.form_data.is_vendor),callback:function ($$v) {_vm.$set(_vm.form_data, "is_vendor", $$v)},expression:"form_data.is_vendor"}},[_vm._v(" "+_vm._s(_vm.$t("static_pages.vendor"))+" ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"send-through",attrs:{"label":_vm.$t('static_pages.appearance_locations')}},[_c('validation-provider',{attrs:{"name":"Appearance locations","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"send-through-checkbox"},[_c('b-form-checkbox',{attrs:{"id":"header","value":1,"unchecked-value":0},model:{value:(_vm.form_data.is_header),callback:function ($$v) {_vm.$set(_vm.form_data, "is_header", $$v)},expression:"form_data.is_header"}},[_vm._v(" "+_vm._s(_vm.$t("static_pages.nav_bar"))+" ")]),_c('b-form-checkbox',{attrs:{"id":"footer","value":1,"unchecked-value":0},model:{value:(_vm.form_data.is_footer),callback:function ($$v) {_vm.$set(_vm.form_data, "is_footer", $$v)},expression:"form_data.is_footer"}},[_vm._v(" "+_vm._s(_vm.$t("static_pages.footer"))+" ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1)],1),_c('b-form-checkbox',{attrs:{"switch":"","value":1,"unchecked-value":0},model:{value:(_vm.form_data.is_active),callback:function ($$v) {_vm.$set(_vm.form_data, "is_active", $$v)},expression:"form_data.is_active"}},[_vm._v(" "+_vm._s(_vm.$t("static_pages.show_this_page"))+" ")]),_c('b-button',{staticClass:"primary-btn mt-3",attrs:{"type":"submit","variant":"primary","block":"","disabled":_vm.isPending},on:{"click":_vm.submitForm}},[_c('span',[_vm._v(_vm._s(_vm.isEdit ? _vm.$t("static_pages.save_changes") : _vm.$t("static_pages.add_page")))])])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }